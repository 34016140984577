export default [
    {
        title: 'menu.setting.parametres',
        icon: ['fal', 'tools'],
        accessRights: 'MVP',
        children: [
            {
                title: 'menu.setting.gerer_type_actes',
                icon: ['far', 'file'],
                accessRights: 'ACTYPE&LICADM',
                to: { name: 'acteTypeListe' },
                href: '#/acte_type_liste'
			},
            {
                title: 'menu.setting.categories',
                icon: ['fal', 'tasks'],
                accessRights: 'LICADM',
                to: { name: 'categoriesHorse' },
                href: '#/categories/horse/'
            },
            {
                title: 'menu.setting.comptabilite',
                icon: ['fal', 'calculator-alt'],
                accessRights: 'FACT|FOUROCR',
                to: { name: 'accountingListe' },
                href: '#/accounting'
            },
            {
                title: 'menu.setting.arctived_accounting',
                icon: ['fal', 'calculator-alt'],
                accessRights: 'FACT',
                to: { name: 'accountingArchivedList' },
                href: '#/accounting_archived'
            },
            {
                title: 'menu.setting.gerer_courrier',
                icon: ['fal', 'envelope'],
                accessRights: 'MVP',
                to: { name: 'courrierType' },
                href: '#/courrier_type'
            },
            {
                title: 'menu.setting.gerer_trigger',
                icon: ['far', 'file'],
                accessRights: 'TRIGG&LICADM',
                to: { name: 'triggersList' },
                href: '#/triggers/list'
            },
            {
                title: 'menu.setting.rights_management',
                icon: ['fal', 'users-cog'],
                accessRights: 'LICADM',
                to: { name: 'userRightsManagement' },
                href: '#/user_rights_management'
			},
            //          {
                //              title: 'menu.setting.localisation',
                //              icon: ['fal', 'map-marker-check'],
                //              accessRights: 'MVP',
                //              to: { name: 'gestionLocalisation' },
                // },
            // {
            //     title: 'menu.setting.importation',
            //     icon: ['fal', 'download'],
            //     accessRights: 'MVP',
            //     to: { name: 'ImportHorse' },
            //     href: '#/import_donnees/horse'
            // },
            {
                title: 'menu.setting.localisation_semence',
                icon: ['fal', 'crutch'],
                accessRights: 'SEMENCE|CONGEL',
                to: { name: 'gestionCuves' },
                href: '#/localisation_semence/cuves'
            },
            {
                title: 'menu.setting.intra_location',
				icon: ['fal', 'location'],
				accessRights: 'MVP',
				to: { name: 'intraLocation' },
                href: '#/intra_location'
			},
            {
                title: 'menu.setting.emails',
				icon: ['far', 'paper-plane'],
				accessRights: 'FACT',
				to: { name: 'emailsMandrill' },
			},
			{
                title: 'menu.setting.notif_management',
                icon: ['far', 'file'],
                accessRights: 'DUCA',
                to: { name: 'acteListeNotificationsClientArea' },
            },
            {
                title: 'menu.setting.tab_config',
                icon: ['far', 'table'],
                accessRights: 'MVP',
                to: { name: 'tabConfig' },
                href: '#/tab_config'
            },
            {
                title: 'menu.setting.advanced_settings',
                icon: ['far', 'cogs'],
                accessRights: 'LICADM',
                to: { name: 'applicationTierce' },
                href: '#/advanced_settings/application_tierce'
            },
			{
                title: 'menu.setting.qualification',
				icon: ['fal', 'tags'],
				accessRights: 'TIERS&LICADM',
				to: { name: 'qualification' },
                href: '#/qualification/tiers'
			},
            {
                title: 'menu.setting.carnet_adresse',
				icon: ['fal', 'tools'],
				accessRights: 'MVP',
				to: { name: 'manageGlobalLocations' },
			},
            {
                title: 'menu.setting.shortcuts',
				icon: ['fal', 'location'],
				accessRights: 'MVP',
				to: { name: 'Shortcuts' },
			},
            {
                title: 'menu.setting.saisons',
                icon: ['fal', 'tasks'],
                accessRights: 'MVP',
                to: { name: 'season' },
                href: '#/saison'
			},
            {
                title: 'menu.setting.vue_tableau_perso',
                icon: ['fal', 'table'],
                accessRights: 'CTABLE',
                to: { name: 'tableConfigFilter' }
            },
            {
                title: 'menu.setting.gestion_vehicule',
                icon: ['fad', 'truck'],
                accessRights: 'MVP',
                to: { name: 'Vehicule' }
            },
            {
                title: 'menu.setting.pax',
                icon: ['cf', 'pax'],
                accessRights: 'CAPAX',
                to: { name: 'PAXCriteres' },
                href: '#/pax/criteres'
            },
            {
                title: 'menu.setting.planning',
                icon: ['fal', 'calendar-week'],
                accessRights: 'MVP',
                to: { name: 'TaskPlanningSetting' },
                href: '#/task_planning/setting'
            }
            // {
   //              title: 'menu.setting.gerer_type_actes_ca',
   //              icon: ['far', 'file'],
   //              accessRights: 'DUCA',
   //              to: { name: 'acteTypeClientArea' },
			// },
        ]
    }
]
